<!--盘点-->
<template>
    <div class="Check">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: right"
                v-model="form"
                size="small"
                label-width="80px"
            >
                <el-row>
                    <el-col :span="6">
                        <!--                        <ef-biz-code v-model="form.code" />-->
                        <el-form-item label="盘点编号">
                            <el-input placeholder="请填写" v-model="form.code" style="width: 215px" />
                        </el-form-item>
                    </el-col>
                    <ef-dept-type-and-dept-select @selectDeptType="setDeptType" @selectDept="setDept" :span="6" />
                    <el-col :span="6">
                        <el-form-item label="盘点状态">
                            <el-select v-model="form.checkStatus" placeholder="请选择">
                                <el-option
                                    v-for="item in InventoryStatus"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="审核状态">
                            <el-select v-model="form.reviewStatus" placeholder="请选择">
                                <el-option
                                    v-for="item in auditStatusOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建日期">
                            <el-date-picker
                                v-model="createDate"
                                type="daterange"
                                value-format="yyyy-MM-dd"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="更新日期">
                            <el-date-picker
                                v-model="updateDate"
                                type="daterange"
                                value-format="yyyy-MM-dd"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button type="primary" @click="handleQuery" size="small" v-if="hasPrivilege('menu.stock.check.open')">
                查询
            </el-button>
            <el-button type="primary" @click="handleCreate" size="small" v-if="hasPrivilege('menu.stock.check.create')">
                新建
            </el-button>
            <el-button type="primary" @click="handleExport" size="small" v-if="hasPrivilege('menu.stock.check.export')">
                导出
            </el-button>
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                border
                ref="table"
                id="printMe"
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="500"
                @selection-change="handleSelectionChange"
            >
                <el-table-column label="全选" type="selection" width="60" align="center" fixed="left" />
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="盘点编码" width="220" prop="code" v-if="showColumn('code')" />
                <el-table-column label="机构类型" width="160" prop="deptTypeName" v-if="showColumn('deptName')" />
                <el-table-column label="机构名称" width="160" prop="deptName" v-if="showColumn('deptName')" />
                <el-table-column label="盘点状态" width="100" prop="checkStatus" v-if="showColumn('checkStatus')">
                    <template slot-scope="scope">
                        <span>{{ scope.row.checkStatus | checkStatusFilter }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="审核状态"
                    width="130"
                    prop="reviewStatus"
                    key="reviewStatus"
                    v-if="showColumn('reviewStatus')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.reviewStatus | reviewStatus }}</span>
                        <span v-if="scope.row.deapprovalFlag" style="margin-left: 10px"
                            ><el-tag type="warning">反审核</el-tag></span
                        >
                    </template>
                </el-table-column>
                <el-table-column label="创建人" width="120" prop="creator" v-if="showColumn('creator')" />
                <el-table-column label="更新人" width="120" prop="updater" v-if="showColumn('creator')" />
                <el-table-column label="创建时间" width="140" prop="createTime" v-if="showColumn('createTime')" />
                <el-table-column label="更新时间" width="140" prop="updateTime" v-if="showColumn('createTime')" />
                <el-table-column
                    label="操作"
                    min-width="415"
                    header-align="center"
                    prop="operate"
                    key="operate"
                    v-if="showColumn('operate')"
                >
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            @click="rowDetail(scope.row)"
                            v-if="hasPrivilege('menu.stock.check.open')"
                            >查看
                        </el-button>
                        <el-button
                            size="mini"
                            type="warning"
                            @click="rowEdit(scope.row)"
                            :disabled="scope.row.disableEditBtn"
                            v-if="hasPrivilege('menu.stock.check.edit')"
                            >编辑
                        </el-button>
                        <el-button
                            type="primary"
                            @click="InventoryEntry(scope.row, '初盘')"
                            size="small"
                            :disabled="scope.row.disableInitialOffer"
                            v-if="
                                hasPrivilege('biz.stock.check') &&
                                (!scope.row.disableInitialOffer || scope.row.checkStatus === 0)
                            "
                            >初盘
                        </el-button>
                        <el-button
                            type="primary"
                            @click="InventoryEntry(scope.row, '复盘')"
                            size="small"
                            :disabled="scope.row.disableReplay"
                            v-if="
                                hasPrivilege('biz.stock.check') &&
                                scope.row.disableInitialOffer &&
                                scope.row.checkStatus !== 0
                            "
                            >复盘
                        </el-button>
                        <el-button
                            type="primary"
                            @click="showReviewDialog(scope.row)"
                            size="small"
                            :disabled="scope.row.disableReviewBtn"
                            v-if="hasPrivilege('menu.stock.check.review')"
                            >审核
                        </el-button>
                        <el-button
                            size="mini"
                            type="danger"
                            @click="handleDelete(scope.row)"
                            :disabled="scope.row.disableDeleteBtn"
                            v-if="hasPrivilege('menu.stock.check.delete')"
                            >删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <ef-pagination
                @pageChange="pageChange"
                :total="total"
                :default-limit="form.limit"
                :current-page="form.page"
            />
        </el-card>
    </div>
</template>

<script>
import Util from 'js/Util';
import StockBizCommon from 'js/StockBizCommon';
import EfDeptTypeAndDeptSelect from 'components/EfDeptTypeAndDeptSelect';
import EfPagination from 'components/EfPagination';
import UrlUtils from 'js/UrlUtils';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';

export default {
    name: 'Check',
    components: {
        EfDeptTypeAndDeptSelect,
        EfPagination,
        CheckTableShowColumnDialog,
    },
    mixins: [CheckTableShowColumn],
    data() {
        return {
            baseUrl: this.$efApi.stockCheckApi.baseUrl,
            selectedBizArr: [],
            form: {
                code: '',
                deptType: null,
                deptCode: null,
                startTime: Util.formatDateOfLastMonth(),
                endTime: Util.nowFormatDate(),
                reviewStatus: '',
                checkStatus: '',
                page: 1,
                limit: 100,
                updateStartTimeStr: '',
                updateEndTimeStr: '',
            },
            total: 0,
            tableData: [],
            InventoryStatus: [
                {
                    label: '请选择',
                    value: '',
                },
                {
                    label: '创建中',
                    value: '0',
                },
                {
                    label: '待初盘',
                    value: '1',
                },
                {
                    label: '初盘中',
                    value: '2',
                },
                {
                    label: '待复盘',
                    value: '3',
                },
                {
                    label: '复盘中',
                    value: '4',
                },
                {
                    label: '已复盘',
                    value: '5',
                },
                {
                    label: '已废弃',
                    value: '6',
                },
                {
                    label: '已完成',
                    value: '7',
                },
            ],
            auditStatusOptions: [
                {
                    label: '请选择',
                    value: '',
                },
                {
                    label: '待审核',
                    value: 0,
                },
                {
                    label: '已审核',
                    value: 2,
                },
                {
                    label: '已拒绝',
                    value: -1,
                },
            ],
            auditStatus: '',
            createDate: [Util.formatDateOfLastMonth(), Util.nowFormatDate()],
            updateDate: [],
            deptSelectComponentLoadingCompleteFlag: false,
        };
    },
    computed: {
        disableChangeStockBtn() {
            if (this.selectedBizArr.length === 0) {
                return true;
            }
            const disableChangeStockBtnFlag = this.selectedBizArr.findIndex((e) => {
                return e.disableChangeStockBtn;
            });
            return disableChangeStockBtnFlag >= 0;
        },
    },
    watch: {
        createDate(newVal) {
            if (newVal) {
                this.form.startTime = newVal[0];
                this.form.endTime = newVal[1];
            } else {
                this.form.startTime = '';
                this.form.endTime = '';
            }
        },
        updateDate(newVal) {
            if (newVal) {
                this.form.updateStartTimeStr = newVal[0];
                this.form.updateEndTimeStr = newVal[1];
            } else {
                this.form.updateStartTimeStr = '';
                this.form.updateEndTimeStr = '';
            }
        },
    },
    mounted() {},
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    methods: {
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
        //禁用/启用按钮
        disableReviewBtn(val) {
            return val !== 0;
        },
        pageChange(page, limit) {
            this.form.page = page;
            this.form.limit = limit;
            this.queryList();
        },
        handleQuery() {
            this.form.page = 1;
            this.queryList();
        },
        setDept(deptCode, deptName) {
            this.form.deptCode = deptCode;
            this.form.deptName = deptName;
        },
        setDeptType(deptType) {
            this.form.deptType = deptType;
            if (deptType) {
                this.handleQuery();
            }
        },
        async queryList() {
            const rst = await this.$efApi.stockCheckApi.page(this.form);
            this.tableData = rst.data.map((e) => {
                return {
                    ...e,
                    //根据状态设置操作按钮禁用或启用
                    deapprovalFlag: e.checkStatus == 5 && e.reviewStatus == 0 && e.canCheckFlag == true,
                    disableEditBtn: !(
                        (e.checkStatus == 0 || e.checkStatus == 5) &&
                        e.reviewStatus == 0 &&
                        e.canCheckFlag == true
                    ),
                    disableInitialOffer: !(
                        (e.checkStatus == 1 || e.checkStatus == 2) &&
                        e.reviewStatus == 0 &&
                        e.canCheckFlag == true
                    ),
                    disableReplay: !(
                        (e.checkStatus == 3 || e.checkStatus == 4) &&
                        e.reviewStatus == 0 &&
                        e.canCheckFlag == true
                    ),
                    disableDeleteBtn: !(
                        (e.checkStatus == 0 || e.checkStatus == 1) &&
                        e.reviewStatus == 0 &&
                        e.canCheckFlag == true
                    ),
                    disableReviewBtn: !(e.checkStatus == 5 && e.reviewStatus == 0),
                    disableChangeStockBtn: !StockBizCommon.validateCanChangeStock(e.reviewStatus, e.stockStatus),
                };
            });
            this.total = rst.count;
        },
        handleCreate() {
            Util.nameJump(this, 'menu.stock.check.create', ['库存管理', '盘点管理', '新建盘点']);
        },
        rowDetail(row) {
            Util.nameJump(this, 'menu.stock.check.detail', ['库存管理', '盘点管理', '盘点详情'], {
                form: row,
                code: row.code,
            });
        },
        rowEdit(row) {
            Util.nameJump(this, 'menu.stock.check.edit', ['库存管理', '盘点管理', '编辑盘点'], {
                form: row,
                code: row.code,
            });
        },
        handleExport() {
            const codes = (this.$refs.table.selection || []).map((e) => e.code);
            if (Util.objectValueAllEmpty(this.form) && codes.length === 0) {
                this.$confirm('无搜索条件时,将仅导出最近三个月的数据', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    this.createDate = [Util.formatDateOfLastThreeMonth(), Util.nowFormatDate()];
                    this.form.startTime = this.createDate[0];
                    this.form.endTime = this.createDate[1];
                    UrlUtils.Export(this, '盘点管理', '/stock/check/export', this.form, codes);
                });
            } else {
                UrlUtils.Export(this, '盘点管理', '/stock/check/export', this.form, codes);
            }
        },
        async handleDelete(row) {
            await this.$efApi.stockCheckApi.deleteBiz(row.code);
            this.handleQuery();
        },
        handleSelectionChange(selections) {
            this.selectedBizArr = selections;
        },
        showReviewDialog(row) {
            Util.nameJump(this, 'menu.stock.check.detail', ['库存管理', '盘点管理', '盘点详情'], {
                form: row,
                code: row.code,
            });
        },
        InventoryEntry(row, type) {
            if (type) {
                Util.nameJump(this, 'menu.stock.check.input', ['库存管理', '盘点管理', '盘点录入'], {
                    form: row,
                    code: row.code,
                    inventoryType: type,
                });
            } else {
                Util.nameJump(this, 'menu.stock.check.input', ['库存管理', '盘点管理', '盘点录入'], {
                    form: row,
                    code: row.code,
                    inventoryType: type,
                });
            }
        },
    },
    filters: {
        checkStatusFilter(val) {
            let str;
            switch (val) {
                case 0:
                    str = '创建中';
                    break;
                case 1:
                    str = '待初盘';
                    break;
                case 2:
                    str = '初盘中';
                    break;
                case 3:
                    str = '待复盘';
                    break;
                case 4:
                    str = '复盘中';
                    break;
                case 5:
                    str = '已复盘';
                    break;
                case 6:
                    str = '已废弃';
                    break;
                case 7:
                    str = '已完成';
                    break;
            }
            return str;
        },
    },
};
</script>

<style scoped>
.Check .el-form-item {
    margin-bottom: 0;
}
</style>
